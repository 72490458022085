<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
        <div class="col col-lg-2"></div>
        <div class="col-md-auto mt-20">
          <rotate-square5></rotate-square5>
        </div>
        <div class="col col-lg-2"></div>
      </div>
      <div class="card card-body printableArea" v-else>
        <h3>
          <b>Tracking</b>
          <!-- <span class="pull-right">#5669626</span> -->
        </h3>
        <hr>
        <div class="row">
          <div class="col-md-12">
            <Table
              :items="items"
              :filter="filters"
              :header="headers"
              :onAction="get"
              :onDetail="doDetail"
              :onEdit="doEdit"
              @onDelete="doDelete"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Table from "../../../components/table/table";
import { RotateSquare5 } from "vue-loading-spinner";

export default {
  components: {
    RotateSquare5,
    Table
  },
  data() {
    return {
      headers: {
        asal_dokumen: {
          label: "Asal Dokumen",
          sortable: true
        },
        nomor_dokumen: {
          label: "Nomor Dokumen",
          sortable: false
        },
        tujuan: {
          label: "Tujuan",
          sortable: true
        },
        perihal: {
          label: "Perihal",
          sortable: true
        },
        tanggal: {
          label: "Tanggal",
          sortable: true,
          type: "date"
        }
      }
    };
  },
  computed: {
    items() {
      return this.$store.state.tracking.items ? this.$store.state.tracking.items.items : [];
    },
    filters() {
      return this.$store.state.tracking.items ? this.$store.state.tracking.items.filters: {};
    },
    state() {
      return this.$store.state.tracking;
    }
  },
  mounted() {
    const state = {
      loaded: false,
      items: []
    };
    this.$store.commit("tracking/STATE", state);
    this.get(state);
  },
  methods: {
    get(val) {
      this.$store.dispatch("tracking/getTracking", val);
    },
    doDetail(val) {
      this.$store.dispatch("tracking/onDetail", val);
    },
    doEdit(val) {
      this.$store.dispatch("tracking/onEdit", val);
    },
    doDelete(val) {
      this.$store.dispatch("tracking/submitDelete", val);
    }
  }
};
</script>
